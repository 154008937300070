import { registerLinkResolver } from 'gatsby-source-prismic-graphql'
import './src/styles/global.css'

registerLinkResolver(({ type, uid }) => {
  switch (type) {
    case 'home':
      return uid === 'home' ? '/' : `/${uid}`
    case 'about':
      return `/about/${uid}`
    case 'service':
      return `/services/${uid}`
    case 'news':
      return `/news/${uid}`
    case 'menu':
      return `/${uid}`
    default:
      if (uid) {
        return `/${uid}`
      }

      return `/${type}`
  }
})

export const onClientEntry = () => {}

export const onRouteUpdate = ({ location }) => {
  if (window.gtag) {
    window.gtag('config', 'GA_MEASUREMENT_ID', {
      page_path: location.pathname + location.search + location.hash,
    })
  }
}
