module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-141261006-1","head":true},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"catamaran","accessToken":"MC5YZmZXNUJFQUFDRUFFQm5L.77-977-9TzIt77-977-977-977-9Q0zvv70KXyYHWXQ777-9ZTcf77-977-977-977-9Zu-_vXUF77-9","path":"/preview","previews":true,"omitPrismicScript":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
